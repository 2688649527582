<template>
  <div class="bottom-margin">
    <FrsRouterBackLink :target="'phbb'">
      <template #text>
        <IxRes>phbb.buttons.backToPhbb</IxRes>
      </template>
    </FrsRouterBackLink>

    <ContentWrapper>
      <template #title>
        <IxRes>phbb.wizard.stepSixTitle</IxRes>
        <div class="sub-title">
          <IxRes>phbb.wizard.label.partTwo</IxRes>:
          <IxRes>phbb.wizard.stepSixAction.partTwo</IxRes>
        </div>
      </template>

      <ApplicationContent v-if="['start', 'classificationLimitExceeded'].includes(step)" class="bottom-margin" />

      <PhBbMapLayer v-if="requiredNutrientMapId && step==='start'" :map="requiredNutrientMap" :opacity="1" />

      <PhBbMapLayer v-if="['calculatedSuccess', 'done', 'classificationLimitExceeded'].includes(step)" :map="calculatedMap" :opacity="1" />

      <IxVectorLayer
        v-if="lane"
        :features="lane.features"
        :vector-style="styles.lane"
        layer-id="lane"
        :z-index="2"
      />

      <div v-if="step==='calculating'">
        <div><IxRes>phbb.create.application.label.calculatingMapPleaseWait</IxRes></div>
        <div class="loading-indicator-box bottom-margin">
          <FrsLoadingIndicator loading />
        </div>
        <div>
          <IxButton large icon="times" @click="onCancelCalculation">
            <IxRes>phbb.create.application.button.cancelCalculation</IxRes>
          </IxButton>
        </div>
      </div>

      <div v-if="step==='classificationLimitExceeded'">
        <HelpBox type="danger" class="bottom-margin">
          <IxRes>phbb.create.application.hints.toManyApplicationValues</IxRes>
        </HelpBox>
      </div>

      <div v-if="step==='done'">
        <IxRes>phbb.create.application.label.mapCreatedSucessfully</IxRes>
      </div>

      <template #footer>
        <div v-if="step==='start'">
          <IxButton large next @click="onCreateCaOApplicationMap">
            <IxRes>phbb.wizard.button.startCreation</IxRes>
          </IxButton>
        </div>
        <div v-else-if="step==='classificationLimitExceeded'">
          <IxButton next icon="refresh" @click="onRecalculate">
            <IxRes>phbb.wizard.button.recalculateMap</IxRes>
          </IxButton>
          <IxButton next @click="onContinueWithoutRecalculation">
            <IxRes>phbb.wizard.button.continueWithoutRecalculation</IxRes>
          </IxButton>
        </div>
      </template>
    </ContentWrapper>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

import {notifications} from 'src/js/infrastructure'
import {lane} from '@frs/components/lane-planning/styles'

import IxButton from '@components/IxButton'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import PhBbMapLayer from '@frs/components/base/PhBbMapLayer'
import ContentWrapper from '@frs/components/phbb/widgets/ContentWrapper'
import FormPartMixin from '@components/forms/FormPartMixin'

import HelpBox from '@components/help/HelpBox'

import IxVectorLayer from '@components/map/IxVectorLayer'
import ApplicationContent from './ApplicationContent'

export default {
  components: {
    ApplicationContent,
    ContentWrapper,
    FrsRouterBackLink,
    FrsLoadingIndicator,
    PhBbMapLayer,
    IxButton,
    HelpBox,
    IxVectorLayer
  },
  mixins: [
    FormPartMixin
  ],
  data () {
    return {
      calculationCancelled: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/phbb', {
      step: state => state.ui.application.step,
      requiredNutrientMapId: state => state.data.application.requiredNutrientMapId,
      caOMap: state => state.data.application.caOMap,
      lane: state => state.data.application.lane
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/phbb', [
      'soilMaps'
    ]),
    isValid () {
      return this.state === 'success'
    },
    requiredNutrientMap () {
      if (!this.requiredNutrientMapId) {
        return null
      }
      const map = this.soilMaps.find(x => x.id === this.requiredNutrientMapId)

      if (!map || !map.zones || !map.zones.length) {
        return null
      }

      return map
    },
    calculatedMap () {
      return this.caOMap
    },
    styles () {
      return {
        lane: lane
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/phbb', [
      'createCaOApplicationMap'
      // 'saveCaOMapWithoutRecalculation'
    ]),
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadSoilMapsForField',
      'loadSoilMapDetails'
    ]),
    ...mapMutations('fieldRecordSystem/phbb', [
      'setDefaultState',
      'setStep',
      'setClassifyGridToMaximumUCUs'
    ]),
    onCancelCalculation () {
      this.setStep('start')
      this.calculationCancelled = true
    },
    async onCreateCaOApplicationMap () {
      this.validate()
      await this.$nextTick()

      if (!this.isValid) return

      this.calculationCancelled = false
      this.setStep('calculating')

      await this.createCaOApplicationMap()

      if (this.calculationCancelled) return

      const classificationInfo = this.caOMap.metaData.classificationInfo

      if (classificationInfo && classificationInfo.exceedsLimit) {
        this.setStep('classificationLimitExceeded')
      } else {
        notifications.success(this.$i18n.translate('phbb.create.application.notifications.mapCreated'))
        this.setStep('done')
      }
    },
    onRecalculate () {
      this.setClassifyGridToMaximumUCUs(true)
      this.onCreateCaOApplicationMap()
    },
    onContinueWithoutRecalculation () {
      // this.saveCaOMapWithoutRecalculation()
      this.setStep('done')
    }
  },
  watch: {
    requiredNutrientMapId (mapId) {
      if (mapId) {
        this.loadSoilMapDetails(mapId)
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.setDefaultState()
      await vm.loadSoilMapsForField(vm.fieldId)
    })
  }
}
</script>

<style lang="scss" scoped>
.sub-title {
  font-size: 1em;
  font-weight: lighter;
}

.bottom-margin {
  margin-bottom: 10px;
}

.loading-indicator-box {
  position: relative;
  width: 100%;
  height: 150px;
}

::v-deep .input-field {
  width: 80px;
}
</style>
