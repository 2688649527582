<template>
  <div>
    <SubHeader>
      <template #title>
        <IxRes>phbb.create.application.subHeader.fileFormatTerminal</IxRes>
      </template>
    </SubHeader>

    <BsRadioList
      v-model="fileFormat" :options="['IsoXML', 'SHAPE']"
      name="fileFormat" compact
      class="bottom-margin"
    >
      <template #default="{option}">
        {{ option }}
      </template>
    </BsRadioList>

    <VueSelectize
      v-model="requiredNutrientMapId"
      class="bottom-margin" required
      :options="requiredNutrientMapIds" :fields="requiredNutrientMapFields"
      allow-empty
      name="requiredNutrientMap"
    >
      <template #label>
        <IxRes>phbb.create.application.selectize.labels.requiredNutrientMap</IxRes>:
      </template>
    </VueSelectize>

    <NumericInput
      v-model="spreaderWorkingWidth"
      class="bottom-margin" :rule="min(1)"
      name="spreaderWorkingWidth" :addon="$i18n.translateUnit('m')"
      required integer
    >
      <template #label>
        <IxRes>phbb.create.application.label.spreaderWorkingWidth</IxRes>:
      </template>
    </NumericInput>

    <ApplicationShapeValues v-if="fileFormat === 'SHAPE'" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'
import BsRadioList from '@components/bootstrap/BsRadioList'
import NumericInput from '@components/forms/NumericInput'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import ApplicationShapeValues from './ApplicationShapeValues'
import SubHeader from '../../widgets/SubHeader'

export default {
  components: {
    ApplicationShapeValues,
    BsRadioList,
    NumericInput,
    SubHeader,
    VueSelectize
  },
  mixins: [
    StandardRulesMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.application.fileFormat',
      'data.application.spreaderWorkingWidth',
      'data.application.requiredNutrientMapId'
    ]),
    ...mapGetters('fieldRecordSystem/phbb', [
      'requiredNutrientMaps'
    ]),
    requiredNutrientMapIds () {
      if (!this.requiredNutrientMaps.length) return []

      return this.requiredNutrientMaps.map(x => x.id)
    },
    requiredNutrientMapFields () {
      return {
        value: id => id,
        text: id => this.requiredNutrientMaps.find(map => map.id === id).name,
        label: id => this.$i18n.format(this.requiredNutrientMaps.find(map => map.id === id).generatedAt, 'datetime')
      }
    }
  }
}
</script>

<style>
.bottom-margin {
  margin-bottom: 10px;
}
</style>
