<template>
  <div>
    <SubHeader>
      <template #title>
        <IxRes>phbb.create.application.subHeader.alignmentInDrivingDirection</IxRes>:
      </template>
      <template #description>
        <IxRes>phbb.create.application.subHeader.description.alignmentInDrivingDirection</IxRes>:
      </template>
    </SubHeader>

    <BsRadioList
      v-model="alignInDrivingDirection" :options="[false, true]"
      name="alignInDrivingDirection" compact
      class="bottom-margin"
    >
      <template #default="{option}">
        <IxRes>phbb.create.application.radio.alignInDrivingDirection.{{ option ? 'align' : 'noAlign' }}</IxRes>
        <VueInfo class="info" placement="top" trigger="hover">
          <template #description>
            <IxRes>phbb.create.application.radio.alignInDrivingDirection.description_{{ option ? 'align' : 'noAlign' }}</IxRes>
          </template>
        </VueInfo>
      </template>
    </BsRadioList>

    <div v-if="alignInDrivingDirection">
      <SubHeader>
        <template #title>
          <IxRes>phbb.create.application.subHeader.laneShape</IxRes>:
        </template>
      </SubHeader>
      <div>
        <span v-if="laneFileName"> {{ laneFileName }} </span>
        <IxRes v-else>phbb.create.application.noLaneShapeSeleced</IxRes>
      </div>
      <IxButton large @click="showModal = true">
        <IxRes>phbb.create.application.button.selectLaneShape</IxRes>
      </IxButton>
    </div>
    <GeometryImportModal v-model="showModal" @load="onLoad" />
  </div>
</template>

<script>
import {mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import BsRadioList from '@components/bootstrap/BsRadioList'
import IxButton from '@components/IxButton'
import GeometryImportModal from '@components/forms/GeometryImportModal'
import VueInfo from '@components/help/VueInfo'

import SubHeader from '../../widgets/SubHeader'

export default {
  components: {
    BsRadioList,
    GeometryImportModal,
    IxButton,
    SubHeader,
    VueInfo
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.application.alignInDrivingDirection',
      'data.application.lane'
    ]),
    laneFileName () {
      if (this.lane && this.lane.features && this.lane.features.length > 0) {
        return `${this.lane.features[0].properties.filename}.shp`
      } else return ''
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/phbb', [
      'setLane'
    ]),
    onLoad (geojson) {
      if (geojson.features && geojson.features.length) {
        this.setLane(geojson)
      }
    }
  }
}
</script>

<style>
.bottom-margin {
  margin-bottom: 10px;
}
.info{
  margin-left: 5px;
}
</style>
